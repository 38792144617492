import React from "react";
import Style from "../Footer.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const FooterBottom = () => {
  let location = useLocation();
  const { footerData } = useSelector((state) => state.footer);

  return (
    <div className={Style.ftr_btm}>
      <div className={Style.btm_strip}>
        <ul>
          {footerData?.details?.menus?.["footer_menu_terms_and_conditions_new"]?.map((item, i) => {
            return (
              <li key={i}>
                <Link to={item?.url} className={location?.pathname === item?.url && Style.active}>
                  {ReactHtmlParser(item?.title)}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <p>{footerData?.details?.footer_more_details?.copyright}</p>
    </div>
  );
};

export default FooterBottom;
