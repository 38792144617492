import React, { useEffect, useState } from "react";
import moment, { months } from "moment";
import axios from "axios";

export const useCurrencyGraph = (selectDays) => {
  const [data, setData] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [selectedDate, setSelectedDate] = useState("1M");
  const [type, setType] = useState();
  const [graphData, setGraphData] = useState([]);
  const [active, setActive] = useState("1M");
  const [timeGrpah, setTimeGrpah] = useState();

  let endDate = moment(new Date().toDateString()).format("yyyy-MM-DD");

  const GRAPH_URL = process.env.REACT_APP_APIURL;

  const getData = (type) => {
    if (type !== undefined) {
      axios({
        method: "get",
        url: `${GRAPH_URL}get-exchangerates-data-json-file?duration=${type}`,

        responseType: "stream",
      })
        .then((res) => {
          let obj = res?.data?.data?.rates;

          let graphTime = res;

          setTimeGrpah(res?.data?.data);

          setData(obj);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    getData(type, selectedCurrency);
  }, [type, selectedCurrency]);

  useEffect(() => {
    if (selectedDate == "1W") {
      setType("week");
    } else if (selectedDate == "2W") {
      setType("two_week");
    } else if (selectedDate == "1M") {
      setType("month");
    } else if (selectedDate == "3M") {
      setType("three_months");
    } else if (selectedDate == "6M") {
      setType("six");
    } else {
      setType("year");
    }
  }, [selectedDate]);

  useEffect(() => {
    if (data && Object.keys(data)?.length != 0) {
      const keyArr = Object.keys(data);
      let GrphData = [];
      keyArr?.map((a, i) => {
        if (type === "two_week") {
          if (
            Math.ceil((5 / 100) * keyArr.length) === i ||
            Math.ceil((15 / 100) * keyArr.length) === i ||
            Math.ceil((30 / 100) * keyArr.length) === i ||
            Math.ceil((45 / 100) * keyArr.length) === i ||
            Math.ceil((55 / 100) * keyArr.length) === i ||
            Math.ceil((70 / 100) * keyArr.length) === i ||
            Math.ceil((95 / 100) * keyArr.length) === i ||
            i + 1 === keyArr?.length
          ) {
            GrphData.push([
              moment(new Date(a).toDateString()).format("DD MMM"),
              data[a][selectedCurrency],
            ]);
          }
        } else if (type === "week") {
          if (
            Math.ceil((10 / 100) * keyArr.length) === i ||
            Math.ceil((20 / 100) * keyArr.length) === i ||
            Math.ceil((30 / 100) * keyArr.length) === i ||
            Math.ceil((40 / 100) * keyArr.length) === i ||
            Math.ceil((50 / 100) * keyArr.length) === i ||
            Math.ceil((60 / 100) * keyArr.length) === i ||
            Math.ceil((70 / 100) * keyArr.length) === i ||
            i + 1 === keyArr?.length
          ) {
            GrphData.push([
              moment(new Date(a).toDateString()).format("DD MMM"),
              data[a][selectedCurrency],
            ]);
          }
        } else if (type === "month") {
          if (
            i === 0 ||
            Math.ceil((15 / 100) * keyArr.length) === i ||
            Math.ceil((30 / 100) * keyArr.length) === i ||
            Math.ceil((48 / 100) * keyArr.length) === i ||
            Math.ceil((63 / 100) * keyArr.length) === i ||
            Math.ceil((78 / 100) * keyArr.length) === i ||
            i + 1 === keyArr?.length
          ) {
            GrphData.push([
              moment(new Date(a).toDateString()).format("DD MMM"),
              data[a][selectedCurrency],
            ]);
          }
        } else if (type === "three_months") {
          if (
            i === 0 ||
            Math.ceil((15 / 100) * keyArr.length) === i ||
            Math.ceil((30 / 100) * keyArr.length) === i ||
            Math.ceil((48 / 100) * keyArr.length) === i ||
            Math.ceil((63 / 100) * keyArr.length) === i ||
            Math.ceil((78 / 100) * keyArr.length) === i ||
            i + 1 === keyArr?.length
          ) {
            GrphData.push([
              moment(new Date(a).toDateString()).format("DD MMM"),
              data[a][selectedCurrency],
            ]);
          }
        } else if (type === "six") {
          if (
            i === 0 ||
            Math.ceil((10 / 100) * keyArr.length) === i ||
            Math.ceil((30 / 100) * keyArr.length) === i ||
            Math.ceil((45 / 100) * keyArr.length) === i ||
            Math.ceil((45 / 100) * keyArr.length) === i ||
            Math.ceil((65 / 100) * keyArr.length) === i ||
            // Math.ceil((75 / 100) * keyArr.length) === i ||
            Math.ceil((85 / 100) * keyArr.length) === i ||
            i + 1 === keyArr?.length
          ) {
            GrphData.push([
              moment(new Date(a).toDateString()).format("DD MMM"),
              data[a][selectedCurrency],
            ]);
          }
        } else if (type === "year") {
          if (
            i === 0 ||
            Math.ceil((10 / 100) * keyArr.length) === i ||
            Math.ceil((30 / 100) * keyArr.length) === i ||
            Math.ceil((40 / 100) * keyArr.length) === i ||
            Math.ceil((60 / 100) * keyArr.length) === i ||
            Math.ceil((70 / 100) * keyArr.length) === i ||
            Math.ceil((90 / 100) * keyArr.length) === i ||
            i + 1 === keyArr?.length
          ) {
            GrphData.push([
              moment(new Date(a).toDateString()).format("DD MMM"),
              data[a][selectedCurrency],
            ]);
          }
        }
      });
      setGraphData([["Month", "Rates"], ...GrphData]);
    }
  }, [data]);

  const graphDays = ["1W", "2W", "1M", "3M", "6M", "1Y"];

  const graphOptions = {
    curveType: "function",
    legend: { position: "none" },
    pointSize: 3,
    series: [{ color: "orange" }],
    chartArea: { left: 40, top: 20, bottom: 40, width: "100%", height: "100%" },
    hAxis: {
      viewWindow: {
        min: 0,
        // max: 20,
      },
      ticks: [0, 50, 100],
    },
  };

  const exchangeDay = (timePeriod) => {
    setSelectedDate(timePeriod);
    setActive(timePeriod);
  };

  return {
    graphData,
    graphOptions,
    graphDays,
    exchangeDay,
    setSelectedCurrency,
    active,
    timeGrpah,
    selectedCurrency,
  };
};
