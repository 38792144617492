import React, { useEffect } from "react";
import ServiceBanner from "../ServiceBanner";
import CurrencyFold from "../CurrencyFold";
import ServiceExchangeRates from "../ServiceExchangeRates";
import ServiceFeatures from "../ServiceFeatures";
import ServiceFaq from "../ServiceFaq";
import ExchangeSendMoney from "../ExchangeSendMoney";
import { useService } from "../../logic/useService";
import { useDispatch, useSelector } from "react-redux";
import { TitleComponent } from "../TitleComponent";
import { changeStatus } from "../../store/slices/serviceSlice";
import ProgressLoader from "../ProgressLoader";

const ServiceCurrencyExchange = () => {
  let loc = window.location.pathname.split("/")[2];

  const result = useService(loc);

  const dispatch = useDispatch();

  const pageService = useSelector((state) => state.pageService);

  useEffect(() => {
    if (!pageService?.[loc]) {
      dispatch(changeStatus(loc));
    }
  }, [loc]);

  return (
    <>
      {pageService?.[loc]?.details[0] ? "" : <ProgressLoader />}
      <TitleComponent
        title={pageService?.[loc]?.details[0]?.wpseo_title}
        description={pageService?.[loc]?.details[0]?.wpseo_metadesc}
      />

      {pageService?.[loc]?.details[0]?.page_sections?.["banner-field-set"]?.status === "true" && (
        <ServiceBanner />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.["graph-status"] === "true" && (
        <CurrencyFold />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.["exchange-rate-status"] === "true" && (
        <ServiceExchangeRates />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.["page-details-section-one"]?.status ===
        "true" && <ExchangeSendMoney />}

      {pageService?.[loc]?.details[0]?.page_sections?.["service-features"]?.status === "true" && (
        <ServiceFeatures />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.faq?.status === "true" && <ServiceFaq />}
    </>
  );
};

export default ServiceCurrencyExchange;
