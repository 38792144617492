import React, { useEffect } from "react";
import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import ServiceBankTransfer from "../ServiceBankTransfer";
import ServiceCashTransfer from "../ServiceCashTransfer";
import ServiceFaq from "../ServiceFaq";
import ServiceFeatures from "../ServiceFeatures";
import ServiceProcess from "../ServiceProcess";
import { useService } from "../../logic/useService";
import { useDispatch, useSelector } from "react-redux";
import { useDimensions } from "../../logic/Dimensions";

import { useLocation } from "react-router-dom";
import { TitleComponent } from "../TitleComponent";
import { changeStatus } from "../../store/slices/serviceSlice";
import ProgressLoader from "../ProgressLoader";

const RetailMoneyTransfer = () => {
  const location = useLocation();

  let loc = location.pathname.split("/")[2];
  const { width } = useDimensions();
  const result = useService(loc);

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!pageService?.[loc]) {
      dispatch(changeStatus(loc));
    }
  }, [loc]);

  return (
    <>
      {pageService?.[loc]?.details[0] ? "" : <ProgressLoader />}
      <TitleComponent
        title={pageService?.[slug]?.details[0]?.wpseo_title}
        description={pageService?.[slug]?.details[0]?.wpseo_metadesc}
      />
      {pageService?.[loc]?.details[0]?.page_sections?.["banner-field-set"]?.status === "true" && (
        <ServiceBanner />
      )}
      {width >= 768 && <ServiceBannerFold />}

      {pageService?.[loc]?.details[0]?.page_sections?.["page-details-section-one"]?.[
        "page-details-section-one-items"
      ]?.[0]?.status == "true" && (
        <ServiceBankTransfer
          data={
            pageService?.[slug]?.details[0]?.page_sections?.["page-details-section-one"]?.[
              "page-details-section-one-items"
            ]?.[0]
          }
        />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.["page-details-section-one"]?.[
        "page-details-section-one-items"
      ]?.[1]?.status == "true" && (
        <ServiceCashTransfer
          data={
            pageService?.[slug]?.details[0]?.page_sections?.["page-details-section-one"]?.[
              "page-details-section-one-items"
            ]?.[1]
          }
        />
      )}
      {pageService?.[slug]?.details[0]?.page_sections?.["service-process"]?.status === "true" && (
        <ServiceProcess />
      )}
      {pageService?.[loc]?.details[0]?.page_sections?.["service-features"]?.status === "true" && (
        <ServiceFeatures />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.faq?.status === "true" && <ServiceFaq />}
    </>
  );
};

export default RetailMoneyTransfer;
