import React, { useEffect, useState } from "react";
import Style from "../Footer.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDimensions } from "../../../../logic/Dimensions";
import { subscribeForm } from "../../../../store/slices/formSlice";
import { useForm } from "../../../../logic/useForm";

const FooterContact = () => {
  const MC_API = process.env.REACT_APP_MAILCHIMB_API_KEY;
  const MCIP_ID = process.env.REACT_APP_MAILCHIMB_ID;

  const { formKey } = useForm();

  const { width } = useDimensions();

  const { footerData } = useSelector((state) => state.footer);

  const [emailSuccess, setEmailSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      form_nonce: formKey?.nonce,
      key: formKey?.key,
    },
    validationSchema,
    enableReinitialize: true,

    onSubmit: (data) => {
      formik.resetForm();
      subscribeSubmit(data);
    },
  });
  const dispatch = useDispatch();

  const subscribeSubmit = async (data) => {
    var payload = new URLSearchParams();
    payload.append("email_address", data.email);
    payload.append("form_nonce", data.form_nonce);
    payload.append("key", data.key);

    const resultAction = await dispatch(subscribeForm(payload));

    if (subscribeForm.fulfilled.match(resultAction)) {
      setEmailSuccess(resultAction?.payload?.data?.success);
      setErrorMsg(resultAction?.payload?.data?.data?.[0]?.message);

      setTimeout(() => {
        setEmailSuccess(false);
        setErrorMsg(false);
      }, 2500);
    }
  };

  return (
    <div className={Style.ftrcontacts}>
      {width > 991 && (
        <div className={`${Style.cols} ${Style.phcol}`}>
          {footerData && <div className={Style.titlesec}>Telephone Number</div>}
          <div className={Style.phcol_detail}>
            <p>
             
              <a
                href={`tel:${footerData?.details?.footer_more_details?.wac_footer_phone_number_uae?.wac_footer_phone_uae}`}
              >
                {
                  footerData?.details?.footer_more_details?.wac_footer_phone_number_uae
                    ?.wac_footer_phone_uae
                }
              </a>
            </p>
          </div>
          <div className={Style.phcol_detail}>
            <p>
              {
                footerData?.details?.footer_more_details?.wac_footer_phone_number_outside_uae
                  ?.wac_footer_phone_outside_uae_text
              }
              <a
                href={`tel:${footerData?.details?.footer_more_details?.wac_footer_phone_number_outside_uae?.wac_footer_phone_outside_uae}`}
              >
                {
                  footerData?.details?.footer_more_details?.wac_footer_phone_number_outside_uae
                    ?.wac_footer_phone_outside_uae
                }
              </a>
            </p>
          </div>
        </div>
      )}
      {width > 991 && (
        <div className={`${Style.cols} ${Style.mailcol}`}>
          {footerData && <div className={Style.titlesec}>Email</div>}
          <div className={Style.detail}>
            <p>
              <a href={`mailto:${footerData?.details?.footer_more_details?.wac_footer_email}`}>
                {footerData?.details?.footer_more_details?.wac_footer_email}
              </a>
            </p>
          </div>
        </div>
      )}

      {footerData && (
        <div className={`${Style.cols} ${Style.newsletcol}`}>
          {width <= 968 ? (
            <div className={Style.titlesec}>Subscribe to Our Newsletter</div>
          ) : (
            <div className={Style.titlesec}>Newsletter</div>
          )}

          <form onSubmit={formik.handleSubmit}>
            <div className={`${Style.input_holder} ${Style.error}`}>
              <input
                id="email"
                name="email"
                type="email"
                className={Style.nwsltr_control}
                placeholder="Your Email Address"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={() => formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className={Style.text_danger}>{formik.errors.email}</div>
              ) : null}
              <button type="submit" className={`btn btn-primary ${Style.nwsltr_btn}`}>
                Subscribe
              </button>
              {emailSuccess && (
                <div className={Style.text_success}>Your have subscribed successfully</div>
              )}
              {errorMsg && (
                <div className={Style.text_success}>
                  <div>Number of submission restrcited.Please try again after 5 minutes</div>
                </div>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
export default FooterContact;
