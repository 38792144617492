import React, { useEffect } from "react";
import Style from "./RatesMobileView.module.scss";
import { Link } from "react-router-dom";
import IcoMoon from "../../Layouts/IcoMooon";
import { Button } from "react-bootstrap";

const RatesMobileView = ({ searchData }) => {
  return (
    <div className={`d-md-none ${Style.tablesec}`}>
      <div className={`d-md-none ${Style.table}`}>
        <div className={Style.tablehead}>
          <div className="container">
            <div className={Style.currency}>Currency</div>
            <div className={Style.dd_dt}>DD/TT</div>
            <div className={Style.fc_buy}>FC Buy</div>
            <div className={Style.fc_cell}>FC Sell</div>
          </div>
        </div>
        <div className={Style.bodynote}>
          <div className="container">
            <ul className={Style.tablebody}>
              {searchData?.length > 0 &&
                searchData?.map((value, i) => {
                  return (
                    <li>
                      <div className={Style.currency} key={i}>
                        {value?.["title-en"]}
                      </div>
                      <div className={Style.dd_dt}>{value?.dd_tt}</div>
                      <div className={Style.fc_buy}>{value?.fc_buy}</div>
                      <div className={Style.fc_cell}>{value?.fc_sell}</div>
                    </li>
                  );
                })}
            </ul>
            <div className={Style.tablenote}>
              <p>
                Rates are indicative and subject to change. Contact our{" "}
                <Link to={"/our-branches"}>Nearest Branch</Link> for the latest
                rates.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.btnwrap}>
        <Link to={"/our-branches"} className={`btn btn-brdr ${Style.loadmore}`}>
          Load More
        </Link>
      </div>
    </div>
  );
};

export default RatesMobileView;
