import React, { useEffect, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/common.scss";
import HomePage from "../pages/Home";
import MediaPage from "../pages/Media";
import MediaDetailPage from "../pages/MediaDetail";
import CareersPage from "../pages/Careers";
import ValueAddedServicesPage from "../pages/ValueAddedServices";
import AboutUsPage from "../pages/AboutUs";
import BuyBackGuaranteePage from "../pages/BuyBackGuarantee";
import UsratiPage from "../pages/Usrati";
import FaqPage from "../pages/Faq";
import EnquirePage from "../pages/Enquire";
import BulkCashDepartmentPage from "../pages/BulkCashDepartment";
import CareersDetailPage from "../pages/CareersDetail";
import BranchLocatorPage from "../pages/BranchLocator";
import ServiceCurrencyExchangePage from "../pages/ServiceCurrencyExchange";
import RetailMoneyTransferPage from "../pages/RetailMoneyTransfer";
import CorporateMoneyTransferPage from "../pages/CorporateMoneyTransfer";
import ScrollToTop from "./ScrollToTop";
import ErrorPage from "../pages/Error";
import TermAndConditions from "../pages/TermAndConditions";
import i18next from "i18next";
import OtherService from "../pages/OtherService";
import DynamicMenuPage from "../pages/DynamicMenuPage";
import ModalThanks from "./ModalThanks";
import ThankyouComponent from "./ThankyouComponent";
import ThankYouPage from "../pages/ThankYou";

const App = () => {
  const loaction = useLocation();
  useEffect(() => {
    let currentLang = localStorage.getItem("lang");
    if (currentLang == "ar") {
      document.getElementById("changeLag").setAttribute("dir", "rtl");
      i18next.changeLanguage("ar");
    } else {
      i18next.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    const allSections = document.querySelectorAll(".anim");
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const element = entry.target;
          element.classList.add("vissible");
        }
      });
    });
    allSections.forEach((section) => {
      observer.observe(section);
    });
  }, [loaction.pathname]);

  return (
    <ScrollToTop>
      <Suspense fallback={null}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/media" element={<MediaPage />} />
          <Route path="/media-detail/:categories_ids/:ID" element={<MediaDetailPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/enquire" element={<EnquirePage />} />
          <Route path="/careers-detail/:slug/:carID" element={<CareersDetailPage />} />
          <Route path="/our-branches" element={<BranchLocatorPage />} />
          <Route path="/our-branches/search/:branchSlug" element={<BranchLocatorPage />} />

          <Route path="/:termsPolicy" element={<TermAndConditions />} />

          <Route path="/services/buy-back-guarantee" element={<BuyBackGuaranteePage />} />
          <Route path="/services/usrati-program" element={<UsratiPage />} />
          <Route path="/services/value-added-services" element={<ValueAddedServicesPage />} />
          <Route path="/services/bulk-cash-department" element={<BulkCashDepartmentPage />} />
          <Route path="/services/currency-exchange" element={<ServiceCurrencyExchangePage />} />
          <Route path="/services/retail-money-transfer" element={<RetailMoneyTransferPage />} />
          <Route
            path="/services/corporate-money-transfer"
            element={<CorporateMoneyTransferPage />}
          />

          <Route path="/services/:commonSlug" element={<OtherService />} />

          <Route path="/:dynamicMenu" element={<DynamicMenuPage />} />
          <Route path="/thanks" element={<ThankYouPage />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </ScrollToTop>
  );
};

export default App;
