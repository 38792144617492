import { useEffect, useState } from "react";
import { getTerms } from "../store/slices/termsAndConditionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useTermsAndConditions = (link) => {
  const dispatch = useDispatch();
  const termsData = useSelector((state) => state.terms);
  const { termsPolicy } = useParams();

  useEffect(() => {
    terms(termsPolicy);
  }, [termsPolicy]);

  const terms = async (path) => {
    await dispatch(getTerms({ slug: path }));
  };

  return {
    termsData: termsData?.termsData,
  };
};
