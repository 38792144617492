import React, { useEffect } from "react";
import Style from "./Branches.module.scss";
import SingleBranch from "./SingleBranch";
import { useBranches } from "../../logic/useBranches";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HomeBranches from "./HomeBranches";
gsap.registerPlugin(ScrollTrigger);

const Branches = ({ homeBranchData }) => {
  const { branchesData, fetchBranchDetails } = useBranches();
  useEffect(() => {
    fetchBranchDetails({
      posts_per_page: 3,
      page: 1,
    });
  }, []);

  return (
    <>
      {window?.location?.pathname !== "/" ? (
        <div className={`${Style.branchloc} `}>
          {branchesData?.details &&
            branchesData?.details?.length > 0 &&
            branchesData?.details?.map((value, i) => {
              return (
                <div key={i} className={"branch-el"}>
                  {/* <SingleBranch value={value} /> */}
                </div>
              );
            })}
        </div>
      ) : (
        <div className={`${Style.branchloc} `}>
          {homeBranchData?.details?.["branch-details-collections"]?.map(
            (value, i) => {
              return (
                <div key={i} className={"branch-el"}>
                  <HomeBranches value={value} />
                </div>
              );
            }
          )}
        </div>
      )}

      {/* 
    {homeBranchData?.details?.["branch-details-collections"]?.map((value, i) => {
          return width >= 992 && <HomeBranches value={value} i={i} />;
        })}  */}
    </>
  );
};

export default Branches;
