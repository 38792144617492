import React, { useEffect, useRef } from "react";
import Style from "./ServiceContentSection.module.scss";
import ServiceContent from "./ServiceContent";
import { useSelector } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const ServiceContentSection = () => {
  useEffect(() => {
    gsap.fromTo(
      `.${Style.imgwrap} img`,
      {
        scale: 1.2,
        scrollTrigger: {
          trigger: `.${Style.imgwrap} img`,
          scrub: 1.2,
        },
      },
      {
        scale: 1,
        scrollTrigger: {
          trigger: `.${Style.imgwrap} img`,
          scrub: 1.2,
        },
      },
      0.2
    );
  });

  const location = useLocation();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];

  let data =
    pageService?.[slug]?.details[0] &&
    pageService?.[slug]?.details[0]?.page_sections?.[
      "page-details-section-one"
    ]?.["page-details-section-one-items"];

  return (
    <>
      <div
        className={
          pageService.loaderClass
            ? `${Style.servicecntsec} ${Style.loaded}`
            : Style.servicecntsec
        }
      >
        <div className="container">
          {data?.map((secData, index) => {
            return (
              <div className={Style.wrapper} key={index}>
                <div className={Style.textcol}>
                  <ServiceContent
                    className={Style.textArea}
                    serviceData={secData}
                    loaderClass={pageService.loaderClass}
                    btnData={pageService?.[slug]?.details[0]?.page_sections}
                  />
                </div>
                <div className={Style.imgcol}>
                  <div className={Style.figwrapper}>
                    <figure className={Style.imgwrap}>
                      <img
                        src={
                          secData &&
                          secData?.["page-details-section-one-image"]?.url
                        }
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ServiceContentSection;
