import React, { useEffect, useRef } from "react";
import Style from "./TermAndConditions.module.scss";
import ReactHtmlParser from "react-html-parser";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTermsAndConditions } from "../../logic/useTermsAndConditions";
import moment from "moment";
import { Link } from "react-router-dom";

import ProgressLoader from "../ProgressLoader";
import ErrorPage from "../../pages/Error";
import { useSelector } from "react-redux";

gsap.registerPlugin(ScrollTrigger);

const TermAndConditions = () => {
  const { termsData, status } = useTermsAndConditions();
  const termsDataStatus = useSelector((state) => state.terms);

  const termscircleone = useRef(),
    termscircletwo = useRef();
  useEffect(() => {
    gsap.to(termscircleone.current, {
      x: -100,
      y: 900,
      duration: 1,
      delay: 0.4,
      opacity: 0.3,
      scrollTrigger: {
        scrub: 2,
      },
    });
    gsap.to(termscircletwo.current, {
      x: 100,
      y: 600,
      duration: 1,
      delay: 0.4,
      opacity: 0.35,
      scrollTrigger: {
        trigger: termscircletwo.current,
        scrub: 2,
      },
    });
  });

  return (
    <>
      {termsDataStatus?.status === "loading" ? (
        <ProgressLoader />
      ) : termsData?.details?.[0]?.post_content ? (
        <div className={Style.terms_policy}>
          <>
            <span className={Style.circleone} ref={termscircleone}></span>
            <span className={Style.circletwo} ref={termscircletwo}></span>
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active">{termsData?.details?.[0]?.post_title}</li>
                </ol>
              </nav>
              <div className={Style.termsandc_mainhead}>
                <header className={`smtitle ${Style.modified}`}>
                  Last Modified:{" "}
                  {moment(termsData?.details?.[0]?.post_modified).format("DD/MM/YYYY")}
                </header>
                <h1>{termsData?.details?.[0]?.post_title} </h1>
              </div>
              <div className={Style.cntsec}>
                {/* <h2>Overview</h2> */}
                <p>{ReactHtmlParser(termsData?.details?.[0]?.post_content)}</p>
              </div>
            </div>
          </>
        </div>
      ) : termsData === null && termsDataStatus?.status === "failed" ? (
        <ErrorPage />
      ) : (
        <ProgressLoader />
      )}
    </>
  );
};

export default TermAndConditions;
