import { Button, Modal } from "react-bootstrap";
import SwiperCore, { Navigation, Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import Style from "./ModalGallery.module.scss";

const ModalGallery = ({ setGalleryModal, galleryModal, data, index }) => {
  SwiperCore.use([Navigation, Autoplay, FreeMode]);

  return (
    <>
      <Modal
        show={galleryModal}
        onHide={() => {
          setGalleryModal(false);
          document.getElementById("changeLag").classList.remove("modal-open");
        }}
        centered
        className="nogap"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <Swiper
              className={Style.gallery_slider}
              speed={300}
              grabCursor={true}
              mousewheelControl={true}
              keyboardControl={true}
              autoHeight={true}
              navigation={true}
              initialSlide={index}
            >
              {data?.map((value, i) => (
                <SwiperSlide>
                  <figure className={Style.postimg} key={i}>
                    <img src={value?.post_thumbnail_url} alt="" />
                  </figure>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalGallery;
