import CommonLayout from "../../components/Layouts/CommonLayout";
import CommonService from "../../components/CommonService";

const CommonServicePage = () => {
  return (
    <CommonLayout>
      <CommonService />
    </CommonLayout>
  );
};

export default CommonServicePage;
