import { useRef, useState } from "react";
import { getSearch } from "../store/slices/searchSlice";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";

export const useSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchData, setSearchData] = useState("");

  const openSearch = (e) => {
    setActiveSearch(true);
    document.querySelector("#common_search").focus();
    document.querySelector("html").classList.add("search_is_open");
  };
  const closeSearch = () => {
    setActiveSearch(false);
    setSearchTerm("");
    setSearchData("");
    document.querySelector("html").classList.remove("search_is_open");
  };
  const dispatch = useDispatch();

  const searchHandleChange = (e) => {
    setSearchTerm(e.target.value);
    doSearch(e.target.value);
  };

  const doSearch = useRef(
    debounce((e) => {
      dispatch(getSearch({ serachSlug: e }))
        .unwrap()
        .then((value) => {
          setSearchData(value);
        });
    }, 500)
  ).current;

  return {
    searchData,
    searchTerm,
    searchHandleChange,
    activeSearch,
    setActiveSearch,
    openSearch,
    closeSearch,
    setSearchTerm,
  };
};
