import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import ThankyouComponent from "../../components/ThankyouComponent";

const ThankYouPage = () => {
  return (
    <CommonLayout>
      <ThankyouComponent />
    </CommonLayout>
  );
};

export default ThankYouPage;
