import React, { useEffect, useState } from "react";
import Menu from "../Menu";
import Footer from "../Footer";
import Cookies from "../../Cookies";

const CommonLayout = ({ children }) => {
  const [isAccept, setIsAccept] = useState(false);

  const handleAccept = () => {
    localStorage.setItem("cookiePolicy", true);
    setIsAccept(!isAccept);
  };

  useEffect(() => {
    if (localStorage.getItem("cookiePolicy") == "true") {
      setIsAccept(false);
    } else {
      setIsAccept(true);
    }
  }, []);

  return (
    <div id="viewport">
      <Menu />
      <div id="scroll-container">
        {isAccept && (
          <Cookies
            handleClickAccept={handleAccept}
            setIsAccept={setIsAccept}
            isAccept={isAccept}
          />
        )}
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default CommonLayout;
