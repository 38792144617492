import React, { useRef } from "react";
import { useSelector } from "react-redux";
import AboutBanner from "../AboutBanner";
import AboutFinance from "../AboutFinance";
import AboutPartnersAndAssociates from "../AboutPartnersAndAssociates";
import AboutStory from "../AboutStory";
import { useAboutUs } from "../../logic/useAboutUs";
import ProgressLoader from "../ProgressLoader";
import { TitleComponent } from "../TitleComponent";
import CertificationsTab from "../CertificationsTab";

const AboutUs = () => {
  const aboutRef = useRef(null);
  const { aboutBannerData, aboutCertificationsData } = useAboutUs();
  const { isActive } = useSelector((state) => state.pageAbout);

  return (
    <div>
      {aboutBannerData ? "" : <ProgressLoader />}
      <TitleComponent
        title={aboutBannerData?.details?.wpseo?.title}
        description={aboutBannerData?.details?.wpseo?.metadesc}
      />
      <AboutBanner
        aboutRef={aboutRef}
        aboutBannerData={aboutBannerData}
        isActive={isActive}
      />
      {aboutBannerData?.details?.["section-featured-image-and-description"]
        ?.status === "true" && (
        <AboutFinance
          aboutRef={aboutRef}
          aboutBannerData={aboutBannerData}
          isActive={isActive}
        />
      )}

      {aboutBannerData?.details["section-what-we-have-to-say"]?.status ===
        "true" && <AboutStory />}

      {aboutCertificationsData?.details?.["section-certifications-and-awards"]
        ?.status === "true" && (
        <CertificationsTab aboutCertificationsData={aboutCertificationsData} />
      )}

      <AboutPartnersAndAssociates />
    </div>
  );
};

export default AboutUs;
