import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Style from "./Cookies.module.scss";

const Cookies = ({ handleClickAccept, setIsAccept, isAccept }) => {
  return (
    <div>
      <div className={Style.cookies_wrapper}>
        <h2 className={Style.cookies_content}>
          This website uses cookies to enhance the user experience.Accepting
          cookies constitutes acceptance of the &nbsp;
          <Link to="/privacy-policy">privacy</Link>&nbsp; statement
        </h2>
        <button
          className={`btn btn-primary ${Style.cookies_accept_button}`}
          onClick={() => handleClickAccept()}
        >
          Accept cookies
        </button>
        <button
          className={Style.cookies_close}
          onClick={() => handleClickAccept()}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default Cookies;
