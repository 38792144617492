import { useEffect } from "react";
import { getService } from "../store/slices/serviceSlice";
import { useDispatch, useSelector } from "react-redux";

export const useService = (params) => {
  const dispatch = useDispatch();
  const pageService = useSelector((state) => state.pageService);

  useEffect(() => {
    if (!pageService?.[params]) {
      fetchPage();
    }
  }, [params]);

  const fetchPage = async () => {
    await dispatch(getService({ slug: params }));
  };

  return fetchPage;
};
