import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Style from "./ServiceCashTransfer.module.scss";
import IcoMoon from "../Layouts/IcoMooon";
import Assets from "../Layouts/Assets";

import ServiceContent from "../ServiceContentSection/ServiceContent";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
gsap.registerPlugin(ScrollTrigger);

const ServiceCashTransfer = () => {
  const cashtrasfershape = useRef();
  useEffect(() => {
    gsap.to(cashtrasfershape.current, {
      x: -150,
      y: 900,
      duration: 1,
      delay: 0.4,
      opacity: 0.2,
      scrollTrigger: {
        trigger: ".serviceprocessec",
        scrub: 2,
      },
    });
  }, []);

  const location = useLocation();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];

  let screenImg =
    pageService?.[slug]?.details?.[0]?.page_sections?.["page-details-section-one"]?.[
      "page-details-section-one-items"
    ]?.[1]?.["page-details-section-one-image"]?.url;

  let floatingImg =
    pageService?.[slug]?.details?.[0]?.page_sections?.["page-details-section-one"]?.[
      "page-details-section-one-items"
    ]?.[1]?.["page-details-section-two-image"]?.url;

  return (
    <div
      className={
        pageService.loaderClass ? `${Style.cashtrasfersec} ${Style.loaded}` : Style.cashtrasfersec
      }
    >
      <span className={Style.circleshape} ref={cashtrasfershape}></span>
      <div className="container">
        <div className={Style.wrapper}>
          <div className={Style.imgcol}>
            <div className={Style.devicewrap}>
              <figure className={Style.imgwrap}>
                <div className={Style.imgsec}>
                  <img src={screenImg} alt="screens" />
                </div>
              </figure>
              {floatingImg && (
                <div className={`${Style.dashboard} bounceanim`}>
                  <img src={floatingImg} alt="screens" />
                </div>
              )}
            </div>
          </div>
          <div className={Style.textcol}>
            <ServiceContent
              serviceData={
                pageService?.[slug]?.details[0] &&
                pageService?.[slug]?.details[0]?.page_sections?.["page-details-section-one"]?.[
                  "page-details-section-one-items"
                ]?.[1]
              }
              btnData={pageService?.[slug]?.details[0]?.page_sections}
              loaderClass={pageService.loaderClass}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCashTransfer;
