import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";
import IcoMoon from "../../Layouts/IcoMooon";
import Style from "./CareerJobList.module.scss";
import { Link } from "react-router-dom";
import { useDimensions } from "../../../logic/Dimensions";

const CareerJobList = ({
  filteredJobs,
  careersJobData,
  selectedDep,
  selectedLoc,
}) => {
  const [page, setPage] = useState(1);
  const [pageFilter, setPageFilter] = useState([]);
  const { width } = useDimensions();

  useEffect(() => {
    if (careersJobData?.details?.jobs) {
      let tempData = filteredJobs.slice(page * 4 - 4, page * 4);
      setPageFilter(tempData);
    }
  }, [filteredJobs, page]);

  useEffect(() => {
    setPage(1);
  }, [selectedDep, selectedLoc]);

  const onPagination = async (pages) => {
    let tempData = filteredJobs.slice(pages * 4 - 4, pages * 4);
    setPageFilter(tempData);
    setPage(pages);
  };

  return (
    <>
      {width >= 768 && filteredJobs?.length == 0 ? (
        <div className="text-center my-2">No Jobs Found</div>
      ) : (
        pageFilter?.map((value, i) => {
          return (
            <div
              className={
                value ? `${Style.resultwrap} ${Style.loaded}` : Style.resultwrap
              }
              key={i}
            >
              <Link
                to={`/careers-detail/${value?.slug}/${value?.ID}`}
                className={Style.resultitem}
              >
                <div className={`${Style.role} `}>
                  <span>Role</span>
                  <div className={Style.valuename}>{value?.role}</div>
                </div>
                <div className={Style.department}>
                  <span>Department</span>
                  <div className={Style.valuename}>{value?.department}</div>
                </div>
                <div className={Style.location}>
                  <span>Location</span>
                  <div className={Style.valuename}>{value?.location}</div>
                </div>
                <div className={Style.eligibility}>
                  <span>Eligibility</span>
                  <div className={Style.valuename}>{value?.eligibility}</div>
                </div>
              </Link>
            </div>
          );
        })
      )}
      {filteredJobs && Math.ceil(filteredJobs?.length / 4) > 1 && (
        <Pagination>
          <Pagination.Prev className={page != 1 ? "active" : " disabled"}>
            <IcoMoon
              icon="swiper-prev"
              size={16}
              onClick={() => {
                onPagination(page - 1);
              }}
            ></IcoMoon>
          </Pagination.Prev>

          {Array(Math.ceil(filteredJobs?.length / 4))
            .fill(Math.ceil(filteredJobs?.length / 4))
            ?.map((value, index) => {
              return (
                <Pagination.Item
                  active={page === index + 1 ? true : false}
                  onClick={() => {
                    onPagination(index + 1);
                  }}
                >
                  {index + 1}
                </Pagination.Item>
              );
            })}

          <Pagination.Next
            className={
              page != Math.ceil(filteredJobs?.length / 4)
                ? "active"
                : "disabled"
            }
          >
            <IcoMoon
              icon="swiper-next"
              size={16}
              onClick={() => {
                onPagination(page + 1);
              }}
            ></IcoMoon>
          </Pagination.Next>
        </Pagination>
      )}
    </>
  );
};

export default CareerJobList;
