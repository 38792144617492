import React, { useEffect } from "react";
import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import ServiceBankTransfer from "../ServiceBankTransfer";
import ServiceCashTransfer from "../ServiceCashTransfer";
import ServiceFaq from "../ServiceFaq";
import ServiceFeatures from "../ServiceFeatures";
import ServiceProcess from "../ServiceProcess";
import Assets from "../Layouts/Assets";
import { useService } from "../../logic/useService";
import { useDimensions } from "../../logic/Dimensions";
import { TitleComponent } from "../TitleComponent";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus } from "../../store/slices/serviceSlice";
import ProgressLoader from "../ProgressLoader";

const CorporateMoneyTransfer = () => {
  let loc = window.location.pathname.split("/")[2];
  const result = useService(loc);
  const { width } = useDimensions();
  const { serviceData } = useSelector((state) => state.pageService);

  const pageService = useSelector((state) => state.pageService);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!pageService?.[loc]) {
      dispatch(changeStatus(loc));
    }
  }, [loc]);

  return (
    <>
      {pageService?.[loc]?.details[0] ? "" : <ProgressLoader />}
      <TitleComponent
        title={pageService?.[loc]?.details[0]?.wpseo_title}
        description={pageService?.[loc]?.details[0]?.wpseo_metadesc}
      />

      {pageService?.[loc]?.details[0]?.page_sections?.["banner-field-set"]?.status === "true" && (
        <ServiceBanner />
      )}
      {width >= 768 && <ServiceBannerFold />}
      {pageService?.[loc]?.details[0]?.page_sections?.["page-details-section-one"]?.[
        "page-details-section-one-items"
      ]?.[0]?.status === "true" && <ServiceBankTransfer />}

      {pageService?.[loc]?.details[0]?.page_sections?.["page-details-section-one"]?.[
        "page-details-section-one-items"
      ]?.[1]?.status === "true" && <ServiceCashTransfer />}

      {pageService?.[loc]?.details[0]?.page_sections?.["service-process"]?.status === "true" && (
        <ServiceProcess />
      )}
      {pageService?.[loc]?.details[0]?.page_sections?.["service-features"]?.status === "true" && (
        <ServiceFeatures />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.faq?.status === "true" && <ServiceFaq />}
    </>
  );
};

export default CorporateMoneyTransfer;
