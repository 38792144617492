import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  statusMediaData: "idle",
  error: null,
  message: null,
  mediaCategoryData: [],
  mediaData: {},
  selectedMedia: "27",
  isActiveClass: false,
};

export const getMediaCategory = createAsyncThunk(
  "media/getMediaCategory",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`home/blog/categories`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMediaData = createAsyncThunk(
  "media/getMediaData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `home/blog?cat=${params.catSlug}&posts_per_page=${params.perPage}&page=${params.page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadMore = createAsyncThunk("media/loadMore", async (params, { rejectWithValue }) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `home/blog?cat=${params.catSlug}&posts_per_page=${params.perPage}&page=${params.page}`
    );

    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    handleSelectedMediaCategory: (state, action) => {
      state.selectedMedia = action.payload;
    },
  },
  extraReducers: {
    [getMediaCategory.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getMediaCategory.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.mediaCategoryData = action.payload?.data?.data;
    },
    [getMediaCategory.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getMediaData.pending]: (state, action) => {
      state.statusMediaData = "loading";
      state.message = null;
    },
    [getMediaData.fulfilled]: (state, action) => {
      state.statusMediaData = "succeeded";
      state.mediaData[state.selectedMedia] = action.payload?.data?.data;

      state.isActiveClass = true;
    },
    [getMediaData.rejected]: (state, action) => {
      state.statusMediaData = "failed";
    },

    [loadMore.pending]: (state, action) => {
      state.statusMediaData = "loading";
      state.message = null;
    },
    [loadMore.fulfilled]: (state, action) => {
      state.statusMediaData = "succeeded";
      state.mediaData[state.selectedMedia].details.blog_list = [
        ...state.mediaData[state.selectedMedia].details.blog_list,
        ...action.payload?.data?.data?.details.blog_list,
      ];

      state.mediaData[state.selectedMedia].details.more_details = {
        ...state.mediaData[state.selectedMedia].details.more_details,
        current_page: action.payload?.data?.data?.details?.more_details?.current_page,
      };

      state.isActiveClass = true;
    },
    [loadMore.rejected]: (state, action) => {
      state.statusMediaData = "failed";
    },
  },
});

export const { handleSelectedMediaCategory } = mediaSlice.actions;

export default mediaSlice.reducer;
