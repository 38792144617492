import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Style from "./CareersDetailRelatedJobs.module.scss";
import IcoMoon from "../Layouts/IcoMooon";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useCareersJobsSingle } from "../../logic/useCareersJobsSingle";
import moment from "moment";
import { useSelector } from "react-redux";

gsap.registerPlugin(ScrollTrigger);

const CareersDetailRelatedJobs = ({ single }) => {
  const { isActive } = useSelector((state) => state.careersJobSingle);

  const realtedcircleshape = useRef();
  useEffect(() => {
    gsap.to(realtedcircleshape.current, {
      x: 200,
      y: "-40%",
      duration: 0.5,
      delay: 0.4,
      opacity: 0.3,
      scrollTrigger: {
        scrub: 2,
        trigger: realtedcircleshape.current,
      },
    });
  });
  return (
    <>
      {single?.details[0]?.related_jobs &&
        single?.details[0]?.related_jobs?.length >= 1 && (
          <div
            className={
              isActive
                ? `${Style.realtedjobsec} ${Style.loaded}`
                : Style.realtedjobsec
            }
          >
            <span className={Style.circleshape} ref={realtedcircleshape}></span>
            <div className="container">
              <div className="smcontainer">
                <div className={Style.headingsec}>
                  <div className={Style.titlesec}>
                    <h2>Related Jobs</h2>
                  </div>
                  <div className={Style.arwbtnwrap}>
                    <Link to="/careers" className="arw-link">
                      <span>
                        <IcoMoon icon="arrow-right" size={16} />
                      </span>
                      View All Jobs
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className={`container ${Style.overflow}`}>
              <div className={Style.smcontainer}>
                <div className={Style.relatedjobs}>
                  <div className={Style.wrapper}>
                    {single?.details[0]?.related_jobs &&
                      single?.details[0]?.related_jobs?.length > 0 &&
                      single?.details[0]?.related_jobs?.map((value, i) => {
                        return (
                          <>
                            <Link
                              to={`/careers-detail/${value?.slug}/${value?.ID}`}
                            >
                              <div key={i}>
                                <div className={Style.realteditem}>
                                  <h3 className={Style.title}>{value?.role}</h3>
                                  <div className={Style.keyvalue}>
                                    <span className={Style.key}>Dept.</span>
                                    &nbsp;:&nbsp;
                                    <span className={Style.value}>
                                      {value?.department}
                                    </span>
                                  </div>
                                  <div className={Style.keyvalue}>
                                    <span className={Style.key}>Loc.</span>
                                    &nbsp;:&nbsp;
                                    <span className={Style.value}>
                                      {value?.location}
                                    </span>
                                  </div>
                                  <div className={Style.date}>
                                    Date:
                                    {moment(value?.post_date_gmt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className={Style.btnwrap}>
              <Link
                to="/careers"
                className={`btn btn-brdr d-md-none ${Style.btn}`}
              >
                <span>View All Jobs</span>
              </Link>
            </div>
          </div>
        )}
    </>
  );
};

export default CareersDetailRelatedJobs;
