import React from "react";
import Style from "../../CertificationsTab.module.scss";
import Accordion from "react-bootstrap/Accordion";

const ServiceAccordion = ({ aboutCertificationsData }) => {
  let data =
    aboutCertificationsData?.details?.["section-certifications-and-awards"]?.[
      "section-certifications-and-awards-items"
    ];

  return (
    <Accordion
      defaultActiveKey={["0"]}
      className={`accordionview ${Style.service_acc}`}
    >
      {data?.map((item, Index) => {
        return (
          <Accordion.Item eventKey={Index} className={Style.acc_item}>
            <Accordion.Header className={Style.acc_head}>
              {item?.["certifications-and-awards-tab-title"]}
            </Accordion.Header>
            <Accordion.Body className={Style.acc_content}>
              <div className={Style.awards}>
                {item?.["certifications-and-awards-image-url-items"]?.map(
                  (imgItem, i) => {
                    return (
                      <>
                        {imgItem?.["certifications-and-awards-image-url"]
                          ?.url && (
                          <div className={Style.award_figwrap} key={i}>
                            <figure>
                              <img
                                src={
                                  imgItem?.[
                                    "certifications-and-awards-image-url"
                                  ]?.url
                                }
                                alt=""
                              />
                            </figure>
                          </div>
                        )}
                      </>
                    );
                  }
                )}
              </div>
              <p>{item?.["certifications-and-awards-description"]}</p>
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default ServiceAccordion;
