import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Style from "./ServiceBanner.module.scss";
import ReactHtmlParser from "react-html-parser";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
import { useDimensions } from "../../logic/Dimensions";
import { useLocation } from "react-router-dom";
import ProgressLoader from "../ProgressLoader";

gsap.registerPlugin(ScrollTrigger);

const ServiceBanner = () => {
  const { width } = useDimensions();

  const servicebnrshape = useRef();
  useEffect(() => {
    gsap.to(servicebnrshape.current, {
      x: 150,
      y: 1000,
      duration: 0.5,
      delay: 0.4,
      width: 50,
      height: 50,
      scrollTrigger: {
        trigger: ".home_servicesec",
        scrub: 2,
      },
    });
  }, []);

  const location = useLocation();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];

  return (
    <>
      {pageService.status == "loading" ? <ProgressLoader /> : ""}
      <div
        className={
          pageService.loaderClass
            ? `${Style.servicebnr} ${Style.loaded}`
            : Style.servicebnr
        }
      >
        <span className={Style.circleshape} ref={servicebnrshape}></span>
        <div className="container">
          <div className={Style.wrapper}>
            <div className={Style.textcol}>
              <div className={Style.textwrap}>
                <div className={Style.textwrap}>
                  <header className={Style.smtitle}>
                    {pageService?.[slug]?.details[0] &&
                      pageService?.[slug]?.details[0]?.page_sections?.[
                        "banner-field-set"
                      ]?.["banner-field-set-first-small-text"]}
                  </header>
                  <h1>
                    {pageService?.[slug]?.details[0] &&
                      pageService?.[slug]?.details[0]?.page_sections?.[
                        "banner-field-set"
                      ]?.["banner-field-set-main-text"]}
                  </h1>
                  <p>
                    {ReactHtmlParser(
                      pageService?.[slug]?.details[0] &&
                        pageService?.[slug]?.details[0]?.page_sections?.[
                          "banner-field-set"
                        ]?.["banner-field-set-main-description"]
                    )}
                  </p>
                  {pageService?.[slug] &&
                    pageService?.[slug]?.details[0]?.page_sections?.[
                      "banner-field-set"
                    ]?.["banner-field-set-button-url"] && (
                      <a
                        href={
                          pageService?.[slug]?.details[0]?.page_sections?.[
                            "banner-field-set"
                          ]?.["banner-field-set-button-url"]
                        }
                        className={`btn btn-brdr ${Style.bnrbtn}`}
                        target="_blank"
                      >
                        <span>
                          {pageService?.[slug]?.details[0] &&
                            pageService?.[slug]?.details[0]?.page_sections?.[
                              "banner-field-set"
                            ]?.["banner-field-set-button-text"]}
                        </span>
                      </a>
                    )}
                </div>
              </div>
            </div>

            <div className={Style.imgcol}>
              <div className={Style.figwrap}>
                {pageService?.[slug]?.details[0] &&
                width <= 992 &&
                location?.pathname.split("/")[2] ==
                  "currency-exchange" ? null : (
                  <figure className={Style.imgsec}>
                    <img
                      src={
                        pageService?.[slug]?.details[0] &&
                        pageService?.[slug]?.details[0]?.page_sections?.[
                          "banner-field-set"
                        ]?.["banner-field-set-image"]?.url
                      }
                      alt=""
                    />
                  </figure>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceBanner;
