import React from "react";
import EnquireAddress from "../EnquireAddress";
import EnquireSection from "../EnquireSection";
import EnquireOtherBranches from "../EnquireOtherBranches";
import { useEnquiry } from "../../logic/useEnquiry";
import { useDimensions } from "../../logic/Dimensions";

import ProgressLoader from "../ProgressLoader";
const Enquire = () => {
  const { width } = useDimensions();
  const { enquiryData } = useEnquiry();

  return (
    <>
      {enquiryData ? "" : <ProgressLoader />}

      <EnquireSection enquiryData={enquiryData} />

      <EnquireAddress enquiryData={enquiryData} />
      {width >= 992 &&
        enquiryData?.details?.["page-more-details"]?.["branch-section-status"] === "true" && (
          <EnquireOtherBranches />
        )}
    </>
  );
};

export default Enquire;
