import React from "react";
import Style from "./ExchangeSendMoney.module.scss";
import { Button } from "react-bootstrap";
import Assets from "../Layouts/Assets";
import IcoMoon from "../Layouts/IcoMooon";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useCurrencyRates } from "../../logic/useCurrencyRates";
import { useDimensions } from "../../logic/Dimensions";

const ExchangeSendMoney = () => {
  const location = useLocation();
  const { currencyRatesData } = useCurrencyRates();
  const { width } = useDimensions();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];

  let screenImg =
    pageService?.[slug]?.details?.[0]?.page_sections?.["page-details-section-one"]?.[
      "page-details-section-one-items"
    ]?.[0]?.["page-details-section-one-image"]?.url;
  let data =
    pageService?.[slug]?.details[0] &&
    pageService?.[slug]?.details[0]?.page_sections?.["page-details-section-one"] &&
    pageService?.[slug]?.details[0]?.page_sections?.["page-details-section-one"]?.[
      "page-details-section-one-items"
    ];

  return (
    <>
      {data && (
        <div
          className={
            pageService.loaderClass
              ? `${Style.exchangesendmoney} ${Style.loaded}`
              : Style.exchangesendmoney
          }
        >
          <div className="container">
            {data?.map((secData, index) => {
              return (
                <div className={Style.wrapper} key={index}>
                  <div className={Style.textcol}>
                    <div className={Style.textwrap}>
                      <h2>{secData?.["page-details-section-one-title"]}</h2>
                      <p>{secData?.["page-details-section-one-description"]}</p>
                    </div>
                  </div>
                  <div className={Style.imgcol}>
                    <div className={Style.devicewrap}>
                      <figure className={Style.imgwrap}>
                        <div className={Style.imgsec}>
                          <img src={screenImg} alt="screens" />
                        </div>
                      </figure>

                      <div className={`${Style.moneychart} bounceanim`}>
                        {width >= 768 &&
                          (secData?.["page-details-section-two-image"]?.url ? (
                            <img src={secData?.["page-details-section-two-image"]?.url} alt="" />
                          ) : (
                            <div className={Style.chartwrap}>
                              <div className={Style.money}>
                                <span className={Style.flag}>
                                  <IcoMoon icon={"AE"} />
                                </span>
                                <div className={Style.currency}>
                                  <span className={Style.main}>1 AED</span>
                                  <span className={Style.convert}>
                                    {currencyRatesData &&
                                      parseFloat(
                                        currencyRatesData?.details[3]?.exchange_rate
                                      ).toFixed(2)}
                                    USD
                                  </span>
                                </div>
                              </div>
                              <div className={Style.figwrap}>
                                <figure>
                                  <img src={Assets.dummy_graph} alt="" />
                                </figure>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ExchangeSendMoney;
