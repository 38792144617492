import React from "react";
import { useCareersJobsSingle } from "../../logic/useCareersJobsSingle";
import Style from "./CareersDetailWhatYoullBeDoing.module.scss";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";

const CareersDetailWhatYoullBeDoing = ({ single }) => {
  const { isActive } = useSelector((state) => state.careersJobSingle);

  return (
    <div
      className={
        isActive ? `${Style.jobdetails} ${Style.loaded}` : Style.jobdetails
      }
    >
      <div className="container">
        <div className={`smcontainer ${Style.wrapper}`}>
          <h2 className="h5">
            {
              single?.details[0]?.more_details?.["job-more_details"]?.[
                "job-more_details-items"
              ][0]?.["job-more_details-items-title"]
            }
          </h2>
          <p>
            {ReactHtmlParser(
              single?.details[0]?.more_details?.["job-more_details"]?.[
                "job-more_details-items"
              ][0]?.["job-more_details-items-description"]
            )}
          </p>

          {single?.details[0]?.more_details?.["job-more_details"]?.[
            "job-more_details-items"
          ][0]?.["job-more_details-items-list"]?.[0]?.[
            "job-more_details-items-list-title"
          ] !== "" && (
            <ul className="list">
              {single?.details[0]?.more_details?.["job-more_details"]?.[
                "job-more_details-items"
              ][0]?.["job-more_details-items-list"] &&
                single?.details[0]?.more_details?.["job-more_details"]?.[
                  "job-more_details-items"
                ][0]?.["job-more_details-items-list"]?.length > 0 &&
                single?.details[0]?.more_details?.["job-more_details"]?.[
                  "job-more_details-items"
                ][0]?.["job-more_details-items-list"]?.map((value, i) => {
                  return (
                    <li key={i}>
                      {value?.["job-more_details-items-list-title"]}
                    </li>
                  );
                })}
            </ul>
          )}
          {single?.details[0]?.more_details?.eligibility && (
            <>
              <h2 className="h5">Eligibility</h2>
              <p>
                {ReactHtmlParser(single?.details[0]?.more_details?.eligibility)}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CareersDetailWhatYoullBeDoing;
