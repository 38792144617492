import { useEffect, useState } from "react";
import { getBranches } from "../store/slices/branchesSlice";
import { useDispatch, useSelector } from "react-redux";

export const useBranches = () => {
  const dispatch = useDispatch();
  const branchesData = useSelector((state) => state.branches);
  const [allBranch, setAllBranch] = useState("");

  const fetchBranchDetails = async (param) => {
    const resultAction = await dispatch(getBranches(param));
    setAllBranch(resultAction?.payload?.data);
  };
  return {
    branchesData: branchesData?.branchesData,
    fetchBranchDetails,
    allBranch,
  };
};
