import React, { useRef, useEffect } from "react";
import Style from "./ExchangeRates.module.scss";
import Assets from "../Layouts/Assets";
import { Link } from "react-router-dom";
import SwiperCore, { Mousewheel, Navigation, Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useInView } from "react-intersection-observer";
import { useDimensions } from "../../logic/Dimensions";

gsap.registerPlugin(ScrollTrigger);

const ExchangeRates = ({ currencyRatesData }) => {
  const { width } = useDimensions();
  SwiperCore.use([Mousewheel, Navigation, Autoplay, FreeMode]);

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  let filteredData = currencyRatesData?.details?.filter((item, i) => {
    return item?.currency_code !== "AED";
  });

  return (
    <div className={`${Style.exchangerates_sec} ${Style.loaded}  `}>
      <div className="container">
        <div className={`${Style.ratestitle}`}>
          <h2 ref={ref} className={`anim `}>
            Today’s exchange rates
          </h2>
        </div>
        <div className={Style.ratesecwrap}>
          <div className={Style.rateswrap}>
            <Swiper
              className={Style.rateslider}
              loop={true}
              slidesPerView={1}
              speed={3000}
              freeMode={true}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              breakpoints={{
                0: {
                  spaceBetween: 20,
                  slidesPerView: 1.6,
                },
                768: {
                  spaceBetween: 60,
                  slidesPerView: 3.5,
                },
                992: {
                  spaceBetween: 100,
                  slidesPerView: 4,
                },
                1200: {
                  spaceBetween: 160,
                  slidesPerView: 4,
                },
                1600: {
                  spaceBetween: 210,
                  slidesPerView: 4,
                },
              }}
            >
              {currencyRatesData?.details &&
                currencyRatesData?.details?.length > 0 &&
                filteredData?.map((value, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className={Style.rateitem} key={i}>
                        <figure className={Style.flag}>
                          <img src={value?.country_flag} />
                        </figure>
                        <div className={Style.countryname}>{value?.title}</div>
                        <div className={Style.rates}>
                          {parseFloat(value?.exchange_rate).toFixed(2)}
                          <span className={Style.currency}>{value?.currency_code}</span>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
        <div className={Style.ratescnt}>
          <p>
            Rates are indicative and subject to change.
            {/* Contact our
            <Link to="/our-branches">nearest Branch</Link> for the latest rates. */}
          </p>
          {width >= 768 && (
            <Link
              to="services/currency-exchange#section-rate"
              className={`btn btn-brdr ${Style.viewall}`}
            >
              <span>View All</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExchangeRates;
