import React, { useEffect } from "react";
import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import ServiceContentSection from "../ServiceContentSection";
import ServiceFaq from "../ServiceFaq";
import ServiceFeatures from "../ServiceFeatures";
import { useService } from "../../logic/useService";
import { useDimensions } from "../../logic/Dimensions";
import { useDispatch, useSelector } from "react-redux";
import { TitleComponent } from "../TitleComponent";
import { changeStatus } from "../../store/slices/serviceSlice";
import ProgressLoader from "../ProgressLoader";

const BulkCashDepartment = () => {
  let loc = window.location.pathname.split("/")[2];

  const result = useService(loc);

  const { width } = useDimensions();

  const { serviceData } = useSelector((state) => state.pageService);
  const pageService = useSelector((state) => state.pageService);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!pageService?.[loc]) {
      dispatch(changeStatus(loc));
    }
  }, [loc]);

  return (
    <>
      {pageService?.[loc]?.details[0] ? "" : <ProgressLoader />}
      <TitleComponent
        title={pageService?.[loc]?.details[0]?.wpseo_title}
        description={pageService?.[loc]?.details[0]?.wpseo_metadesc}
      />
      {pageService?.[loc]?.details[0]?.page_sections?.["banner-field-set"]?.status === "true" && (
        <ServiceBanner />
      )}
      {width >= 768 && <ServiceBannerFold />}

      {pageService?.[loc]?.details[0]?.page_sections?.["page-details-section-one"]?.status ===
        "true" && <ServiceContentSection />}
      {pageService?.[loc]?.details[0]?.page_sections?.["service-features"]?.status === "true" && (
        <ServiceFeatures />
      )}
      {pageService?.[loc]?.details[0]?.page_sections?.faq?.status === "true" && <ServiceFaq />}
    </>
  );
};

export default BulkCashDepartment;
