import React, { useEffect } from "react";
import ServiceBanner from "../ServiceBanner";
import ServiceBannerFold from "../ServiceBanner/ServiceBannerFold";
import HomeServicesTab from "../HomeServicesTab";
import ServiceFeatures from "../ServiceFeatures";
import ServiceFaq from "../ServiceFaq";
import { useService } from "../../logic/useService";
import { useDispatch, useSelector } from "react-redux";
import { useDimensions } from "../../logic/Dimensions";
import { TitleComponent } from "../TitleComponent";
import { useLocation } from "react-router-dom";
import { changeStatus } from "../../store/slices/serviceSlice";
import ProgressLoader from "../ProgressLoader";

const ValueAddedServices = () => {
  let loc = window.location.pathname.split("/")[2];

  const result = useService(loc);
  const { width } = useDimensions();

  const { serviceData, loaderClass } = useSelector((state) => state.pageService);

  const location = useLocation();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!pageService?.[loc]) {
      dispatch(changeStatus(loc));
    }
  }, [loc]);

  return (
    <div>
      {pageService?.[slug]?.details[0] ? "" : <ProgressLoader />}
      <TitleComponent
        title={pageService?.[slug]?.details[0]?.wpseo_title}
        description={pageService?.[slug]?.details[0]?.wpseo_metadesc}
      />
      {pageService?.[loc]?.details[0]?.page_sections?.["banner-field-set"]?.status === "true" && (
        <ServiceBanner />
      )}
      {width >= 768 && <ServiceBannerFold />}

      {pageService?.[loc]?.details[0]?.page_sections?.["services-provide"]?.status === "true" && (
        <HomeServicesTab
          result={result}
          serviceData={
            pageService?.[slug]?.details[0].page_sections?.["services-provide"]?.[
              "banner-featured-points-items"
            ]
          }
          loaderClass={loaderClass}
        />
      )}
      {pageService?.[loc]?.details[0]?.page_sections?.["service-features"]?.status === "true" && (
        <ServiceFeatures />
      )}

      {pageService?.[loc]?.details[0]?.page_sections?.faq?.status === "true" && <ServiceFaq />}
    </div>
  );
};

export default ValueAddedServices;
