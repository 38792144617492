import React from "react";
import { Link } from "react-router-dom";
import Style from "../../Footer.module.scss";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const ServicesMenu = () => {
  let location = useLocation();

  const { footerData } = useSelector((state) => state.footer);

  return (
    <div className="mobile-hide">
      {footerData && <div className={Style.titlesec}>Services</div>}
      <div className={Style.menuwrap}>
        <ul>
          {footerData?.details?.menus?.footer_menu_services_new &&
            footerData?.details?.menus?.footer_menu_services_new?.length > 0 &&
            footerData?.details?.menus?.footer_menu_services_new?.map((value, i) => {
              let activeService1 = location?.pathname == value?.url;
              return (
                <li key={i} className={activeService1 ? Style.active : ""}>
                  <Link to={`${value?.visiblity === "disable" ? "" : value?.url}`}>
                    {value?.title}
                  </Link>{" "}
                </li>
              );
            })}
        </ul>
        <ul>
          {footerData?.details?.menus?.footer_menu_usrati_new &&
            footerData?.details?.menus?.footer_menu_usrati_new?.length > 0 &&
            footerData?.details?.menus?.footer_menu_usrati_new?.map((value, i) => {
              let activeService2 = location?.pathname == value?.url;
              return (
                <li key={i} className={activeService2 ? Style.active : ""}>
                  <Link to={value?.url ? value?.url : "/"}>{value?.title}</Link>{" "}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default ServicesMenu;
