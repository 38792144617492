import React, { useRef } from "react";
import { useSelector } from "react-redux";

import CareerBanner from "../CareerBanner";
import CareerCultureAndValues from "../CareerCultureAndValues";
import CareerFuture from "../CareerFuture";
import CareerJobSearch from "../CareerJobSearch";
import CareerResume from "../CareerResume";
import { useCareers } from "../../logic/useCareers";
import { useDimensions } from "../../logic/Dimensions";
import ProgressLoader from "../ProgressLoader";

import { useCareersJobs } from "../../logic/useCareersJobs";

const Careers = () => {
  const result = useCareers();

  const { careersData, isActive } = useSelector((state) => state.careers);
  const { careersJobData } = useCareersJobs();

  const { width } = useDimensions();

  const dropRef = useRef(null);
  const posRef = useRef(null);

  return (
    <div>
      {careersData ? "" : careersJobData ? "" : <ProgressLoader />}
      {careersData?.details?.page_sections?.["banner-field-set"]?.status === "true" && (
        <CareerBanner careersData={careersData} isActive={isActive} />
      )}
      {careersData?.details?.page_sections?.["job-serach-field-set"]?.status === "true" && (
        <CareerJobSearch careersJobData={careersJobData} posRef={posRef} />
      )}
      {careersData?.details?.page_sections?.["our-culture-and-values-field-set"]?.status ===
        "true" && <CareerCultureAndValues dropRef={dropRef} />}
      {width >= 768 &&
        careersData?.details?.page_sections?.["build-future-with-us-field-set"]?.status ===
          "true" && <CareerFuture />}
      {careersData?.details?.page_sections?.["drop-your-resume-field-set"]?.status === "true" && (
        <CareerResume dropRef={dropRef} careersJobData={careersJobData} posRef={posRef} />
      )}
    </div>
  );
};

export default Careers;
