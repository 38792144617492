import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMenu } from "../../store/slices/menuSlice";

import ServiceCurrencyExchangePage from "../ServiceCurrencyExchange";
import ValueAddedServicesPage from "../ValueAddedServices";
import UsratiPage from "../Usrati";
import BuyBackGuaranteePage from "../BuyBackGuarantee";
import BulkCashDepartmentPage from "../BulkCashDepartment";
import RetailMoneyTransferPage from "../RetailMoneyTransfer";
import CorporateMoneyTransferPage from "../CorporateMoneyTransfer";
import ErrorPage from "../Error";
import DynamicMenuPage from "../DynamicMenuPage";
import CommonServicePage from "../CommonServicePage";

const OtherService = () => {
  const { commonSlug } = useParams();
  const dispatch = useDispatch();
  const menuData = useSelector((state) => state.menu);

  useEffect(() => {
    if (menuData.status === "idle") {
      menuHeader();
    }
  });

  const menuHeader = async () => {
    await dispatch(getMenu());
  };

  const checkComponent = (commonSlug) => {
    let servicList = menuData?.menuData?.details
      ?.find((o) => o?.sub_menu?.length > 0)
      ?.sub_menu?.find((s) => s?.url === "/services/" + commonSlug);

    let newSlug = "/services/" + commonSlug;

    let serRes = menuData?.menuData?.details
      ?.filter((item) => item.title === "Services")?.[0]
      ?.sub_menu?.find((item) => {
        return item?.sub_menu?.some((submenuItem) => {
          return submenuItem?.url === newSlug;
        });
      });

    let resItem = serRes?.sub_menu?.filter((item) => item?.url === newSlug)?.[0];

    let isTemp = servicList?.template ? servicList?.template : resItem?.template;

    switch (isTemp) {
      case "c-exchange":
        return <ServiceCurrencyExchangePage />;
      case "v-added-services":
        return <ValueAddedServicesPage />;
      case "ustrati":
        return <UsratiPage />;
      case "buy-back-guarantee":
        return <BuyBackGuaranteePage />;
      case "bcd":
        return <BulkCashDepartmentPage />;
      case "rt-money-transfer":
        return <RetailMoneyTransferPage />;
      case "c-money-transfer":
        return <CorporateMoneyTransferPage />;
      case "c-r-p":
        return <CommonServicePage />;

      default:
        return menuData?.status === "succeeded" && isTemp === undefined ? <ErrorPage /> : "";
    }
  };

  return checkComponent(commonSlug);
};

export default OtherService;
